import Vue from "vue";

export const createCalendar = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: "/ui-calendars/",
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const listCalendars = (queryParams) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: "/ui-calendars/",
        params: queryParams,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getCalendar = (id) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/ui-calendars/${id}/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const shareCalendar = (id, payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-calendars/${id}/share-calendar/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const createCalendarOnRemote = (id) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-calendars/${id}/create-calendar/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const setVisibilityCalendar = (id, payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-calendars/${id}/visibility/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const updateCalendar = (id, payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "patch",
        url: `/ui-calendars/${id}/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const addUserToCalendar = (id, payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-users/${id}/add-to-calendar/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const getRidersAvailableInCalendar = (
  id,
  queryParams,
  extraConfiguration
) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/ui-calendars/${id}/riders/`,
        params: queryParams,
        ...extraConfiguration,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const createCalendarEvent = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-events/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const readCalendarEvent = (id) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/ui-events/${id}/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const updateCalendarEvent = (id, payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "patch",
        url: `/ui-events/${id}/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const deleteCalendarEvent = (id, payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "delete",
        url: `/ui-events/${id}/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const listCalendarEvents = (queryParams, extraConfiguration) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/ui-events/`,
        params: queryParams,
        ...extraConfiguration,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const syncCalendarEvent = (id) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-events/${id}/create-event/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });
