<template>
  <transition
    enter-class="opacity-0"
    enter-active-class="transition-opacity duration-700 ease-linear"
    leave-class="opacity-0"
    leave-active-class="transition-opacity duration-700 ease-linear"
  >
    <div
      class="text-gray-600 text-sm relative font-medium text-justify max-w-sm m-auto rounded-lg shadow-lg p-4 mb-4 overflow-hidden bg-white"
    >
      <div class="flex items-center">
        <div
          :class="alertType"
          class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
        >
          <CheckIcon
            v-if="alertType === 'success'"
            class="w-5 h-5"
            aria-hidden="true"
          ></CheckIcon>
          <XIcon
            v-if="alertType === 'danger'"
            class="w-5 h-5"
            aria-hidden="true"
          ></XIcon>
          <ExclamationIcon
            v-if="alertType === 'warning'"
            class="w-5 h-5"
            aria-hidden="true"
          ></ExclamationIcon>
        </div>
        <div class="ml-3 text-sm font-normal">{{ message }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
import { CheckIcon, XIcon, ExclamationIcon } from "@vue-hero-icons/outline";
export default {
  name: "uiToastNotification",
  props: {
    message: {
      type: String,
      default: null,
    },
    alertType: {
      type: String,
      validator: (value) => ["success", "danger", "warning"].includes(value),
      default: "success",
    },
    displayTime: {
      type: Number,
      default: 2000,
    },
    delayTime: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CheckIcon,
    XIcon,
    ExclamationIcon,
  },
  mounted() {
    let toastContainer = document.querySelector(".toastr-notification");
    if (!toastContainer) {
      toastContainer = document.createElement("div");
      toastContainer.classList.add("toastr-notification");
      toastContainer.classList.add("top-right");
      document.body.appendChild(toastContainer);
    }
    toastContainer.appendChild(this.$el);

    // Display toastr with delayTime milliseconds
    setTimeout(() => this.showToastr(), this.delayTime);
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    showToastr() {
      this.id = setTimeout(() => this.hideToastr(), this.displayTime);
    },
    hideToastr() {
      clearTimeout(this.id);

      setTimeout(() => {
        this.$destroy();
        this.$el.remove();
      }, 150);
    },
  },
};
</script>

<style lang="scss">
// Important here: DO NOT WRAP THIS BLOCK IN SCOPED SEMANTICS BECAUSE WON'T WORK
.toastr-notification {
  @apply items-center align-middle absolute z-[999999] w-full pointer-events-none;

  &.top-right {
    @apply top-5;
    > div {
      @apply mr-5;
    }
  }
  .success {
    @apply text-green-500 bg-green-100;
  }
  .danger {
    @apply text-red-500 bg-red-100;
  }
  .warning {
    @apply text-orange-500 bg-orange-100;
  }
}
</style>
