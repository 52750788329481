import Vue from "vue";

export const users = (payload, extraConfiguration) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: "/ui-users/",
        params: payload,
        ...extraConfiguration,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const user = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/ui-users/${payload.id}/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const userHubspotProxy = (userId) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/ui-users-hs/${userId}/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });
