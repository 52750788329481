import dayjs from "dayjs";

const navigatorLocale = navigator.language.toLowerCase().slice(0, 2) || "es-ES";

const formatDate = (date, options) => {
  if (!date || date === "-") {
    return date;
  }

  if (!options) {
    options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
  }

  if (typeof date !== Date) {
    date = new Date(date);
  }

  return new Intl.DateTimeFormat(navigatorLocale, options).format(date);
};

/* =========================== RELATIVES ======================================= */
const TIMESLOTS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

const formatDateWithRelative = (date, options, slot = "days") => {
  if (typeof date !== Date) {
    date = new Date(date);
  }

  let duration = (date - new Date()) / 1000;
  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: "auto",
  });
  let slotIndex = Number.POSITIVE_INFINITY;

  for (
    let timeSlotIndex = 0;
    timeSlotIndex < TIMESLOTS.length;
    timeSlotIndex++
  ) {
    const timeSlot = TIMESLOTS[timeSlotIndex];

    if (timeSlot.name === slot) {
      slotIndex = timeSlotIndex;
    }

    if (Math.abs(duration) < timeSlot.amount && slotIndex >= timeSlotIndex) {
      return formatter.format(Math.ceil(duration), timeSlot.name);
    }
    duration /= timeSlot.amount;
  }

  // Return data as normal
  return formatDate(date, options);
};

/* =========================== API-COMPLIANT ======================================= */
const formatDateAPICompliant = (date, formatString = "YYYY-MM-DD") => {
  return dayjs(date).format(formatString);
};

const formatDateToDate = (date, format = "YYYY-MM-DD") => {
  return dayjs(date, { format: format }).startOf("day");
};

const format = (Vue) => {
  // Add here new prototype
  Vue.prototype.$formatDate = formatDate;
  Vue.prototype.$formatDateAPI = formatDateAPICompliant;
  Vue.prototype.$formatDateWithRelative = formatDateWithRelative;
  Vue.prototype.$formatDateToDate = formatDateToDate;

  // Add here new filters
  Vue.filter("formatDate", formatDate);
  Vue.filter("formatDateWithRelative", formatDateWithRelative);
};

export default format;
export { format };
