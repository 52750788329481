import Vue from "vue";

export const createEstimation = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: "/ui-budget-estimation/",
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const readEstimation = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/ui-budget-estimation/${payload.id}/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const patchEstimation = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "patch",
        url: `/ui-budget-estimation/${payload.id}/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const deleteEstimation = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "delete",
        url: `/ui-budget-estimation/${payload.id}/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const publishEstimation = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-budget-estimation/${payload.id}/publish-in-hubspot/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const estimationHistory = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: payload.url,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const patchHistoryElementTitle = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "patch",
        url: `/ui-budget-estimation-update-history/${payload.history_id}/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });
