import Vue from "vue";

export const listWarehouses = (queryParams) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: "/ui-warehouses/",
        params: queryParams,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const warehouseCities = () =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: "/ui-warehouses/cities/",
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const linkRidersToWarehouse = (id, payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-warehouses/${id}/link-riders/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });

export const linkCalendarsToWarehouse = (id, payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: `/ui-warehouses/${id}/link-calendars/`,
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  });
