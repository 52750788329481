import { clientAuth } from "./index";
import store from "@/store";
import Vue from "vue";

/**
 * Helper methods
 */
const MAX_429_RETRIES = 4;
const exponentialBackoffDelay = (retryAttempt = 0) => {
  const delay = Math.pow(2, retryAttempt) * 600;
  const randomSum = delay * 0.4 * Math.random(); // 0-40% of the delay
  return delay + randomSum;
};

export const responseErrorInterceptor = async (error) => {
  const originalRequest = error.config;
  const response = error.response;
  let delay = 0;

  // Add retryCount to config if not present
  originalRequest._tooManyRetryCount = originalRequest._tooManyRetryCount || 0;

  // Logout user if we get 401 status error and original request is auth/refresh
  if (
    response &&
    response.status === 401 &&
    originalRequest.url === "/auth/refresh/"
  ) {
    store.dispatch("auth/LOGOUT");
  }

  if (
    response &&
    (!originalRequest._retry401 || !originalRequest._retry429) &&
    [401, 429].includes(response.status)
  ) {
    // If we are facing a 401 error, refresh token
    if (response.status === 401) {
      originalRequest._retry401 = true;
      const refreshToken = Vue.prototype.$getToken("refresh-token");
      try {
        const response = await store.dispatch("auth/REFRESH_TOKEN", {
          refresh: refreshToken,
        });
        originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
      } catch (error) {
        store.dispatch("auth/LOGOUT");
      }
    }

    if (response.status === 429) {
      originalRequest._tooManyRetryCount += 1;
      originalRequest._retry401 = false;
      originalRequest._retry429 =
        originalRequest._tooManyRetryCount >= MAX_429_RETRIES;

      // Set the delay time before retry the request
      delay = exponentialBackoffDelay(originalRequest._tooManyRetryCount);
    }

    // Finally, run the request
    return new Promise((resolve) =>
      setTimeout(() => resolve(clientAuth(originalRequest)), delay)
    );
  }

  // In another case, reject
  return Promise.reject(error);
};

export const requestInterceptor = (config) => {
  const accessToken = Vue.prototype.$getToken("access-token");
  config.headers.Authorization = `Bearer ${accessToken}`;

  // TODO: set accept-language header using inferred language from domain
  return config;
};
