var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "enter-class": "opacity-0",
      "enter-active-class": "transition-opacity duration-700 ease-linear",
      "leave-class": "opacity-0",
      "leave-active-class": "transition-opacity duration-700 ease-linear"
    }
  }, [_c('div', {
    staticClass: "text-gray-600 text-sm relative font-medium text-justify max-w-sm m-auto rounded-lg shadow-lg p-4 mb-4 overflow-hidden bg-white"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg",
    class: _vm.alertType
  }, [_vm.alertType === 'success' ? _c('CheckIcon', {
    staticClass: "w-5 h-5",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _vm._e(), _vm.alertType === 'danger' ? _c('XIcon', {
    staticClass: "w-5 h-5",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _vm._e(), _vm.alertType === 'warning' ? _c('ExclamationIcon', {
    staticClass: "w-5 h-5",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ml-3 text-sm font-normal"
  }, [_vm._v(_vm._s(_vm.message))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }